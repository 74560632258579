import React, { useState, useCallback, useRef } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { FormHandles } from '@unform/core'
import { Form } from '@unform/web'
import * as Yup from 'yup'

import {
  FiMail,
  FiUser,
  FiCheck,
  FiSmartphone,
  FiPercent,
  FiX,
} from 'react-icons/fi'

import { AiOutlineQuestionCircle } from 'react-icons/ai'

import Modal from 'react-modal'
import { MdSecurity } from 'react-icons/md'
import getValidationErrors from '../../utils/getValidationErrors'
import usePersistedState from '../../hooks/usePersistedState'

import InputSelect from '../../components/InputSelect'
import InputHidden from '../../components/InputHidden'
import Button from '../../components/Button'
import Header from '../../components/Header'
import Input from '../../components/Input'

import {
  UserData,
  Participant,
  ParticipantDetails,
} from '../../utils/interfaces'
import { Container, Content, BtnVoltar } from './styles'
import validaCPF from '../../utils/validaCPF'

const NovoParticipante: React.FC = () => {
  const [userData] = usePersistedState<UserData>('userData', {} as UserData)
  const [userDetails] = usePersistedState<ParticipantDetails>(
    'userDetails',
    {} as ParticipantDetails,
  )

  const [, setSaveAndAddNew] = useState(false)

  const [grauParent, setGrauParent] = useState({
    label: userDetails.dcrGrauParentesco,
    value: userDetails.grauParentesco,
  })

  const [participants, setParticipants] = usePersistedState<Participant[]>(
    'participantsGroup',
    [],
  )

  const [thisParticipantData] = useState<UserData>({} as UserData)

  const [thisParticipant, setThisParticipant] = useState<Participant>(
    {} as Participant,
  )

  const [thisParticipantDetails] = useState<ParticipantDetails>(
    {} as ParticipantDetails,
  )

  const [isModalOpen, setIsModalOpen] = useState(false)

  const formRef = useRef<FormHandles>(null)
  const history = useHistory()
  const location = useLocation()

  const arr = participants.map(participant => participant.details.proporcao)

  let soma = 0
  for (let i = 0; i < arr.length; i += 1) {
    soma += arr[i]
  }
  const [vlrProporcao, setVlrProporcao] = useState(100 - soma)
  const [contribValue] = useState(0)
  const [contribTime] = useState(0)

  const handleChangeGrauParentesco = useCallback(e => {
    const t = e
    setGrauParent(t)
  }, [])

  const setContribTimeAndValue = useCallback(() => {
    const fieldV = formRef.current?.getFieldRef('contribution')
    const fieldT = formRef.current?.getFieldRef('years')

    fieldV.value = contribValue
    fieldT.value = contribTime
  }, [contribTime, contribValue])

  // const handleAddNovo = useCallback(() => {
  //   setSaveAndAddNew(true)
  //   const field = formRef.current?.getFieldRef('addNew')
  //   field.value = true
  //   setContribTimeAndValue()
  //   formRef.current?.submitForm()
  // }, [setContribTimeAndValue])

  const handleJustSave = useCallback(() => {
    setSaveAndAddNew(false)
    const field = formRef.current?.getFieldRef('addNew')
    field.value = false
    setContribTimeAndValue()
    formRef.current?.submitForm()
  }, [setContribTimeAndValue])

  const handleSubmit = useCallback(
    async formData => {
      try {
        formRef.current?.setErrors({})

        const schema = Yup.object().shape({
          name: Yup.string()
            .required('Seu nome é obrigatório.')
            .matches(/\s/g, 'Digite o nome completo')
            .min(3, 'Digite o nome completo'),
          email: Yup.string().email('Digite um e-mail válido'),
          phone: Yup.string()
            .max(15, 'Telefone inválido')
            .min(14, 'Telefone inválido'),
          proporcao: Yup.number()
            .typeError('Informe a proporção')
            .required('Informe a proporção')
            .test('', `Informe a proporção`, () => !(vlrProporcao === 0))
            .test(
              '',
              `Percentual inválido. Escolha um valor entre 1% e 100%`,
              () => vlrProporcao <= 100 || soma > 0,
            )
            .test(
              '',
              `Você já distribuiu ${soma}% da proporção. Restam ${
                100 - soma
              }% para serem disbribuídos`,
              () => vlrProporcao + soma <= 100 || soma === 0,
            ),
          grauParentesco: Yup.string().required('Informe o grau de parentesco'),
          cpf: Yup.string()
            .required('Campo obrigatório.')
            .test('', 'CPF já utilizado em outro cadastro', function v() {
              const t2 =
                (participants.filter(
                  participant => participant.data.cpf === formData.cpf,
                ).length <= 0 &&
                  formData.cpf !== userData.cpf) ||
                formData.cpf === ''
              return t2
            })
            .test('', 'CPF inválido', function t() {
              const teste =
                validaCPF(formData.cpf.replaceAll('.', '').replace('-', '')) ||
                formData.cpf === ''
              return teste
            }),
        })

        await schema.validate(formData, { abortEarly: false })

        setThisParticipant({
          ...thisParticipant,
          data: {
            ...thisParticipantData,
            name: formData.name,
            email: formData.email,
            cpf: formData.cpf,
            phone: formData.phone,
          },
          details: {
            ...thisParticipantDetails,
            grauParentesco: grauParent.value,
            dcrGrauParentesco: grauParent.label,
            proporcao: vlrProporcao,
          },
        })

        setParticipants([
          ...participants,
          {
            ...thisParticipant,
            data: {
              ...thisParticipantData,
              name: formData.name,
              email: formData.email,
              cpf: formData.cpf,
              phone: formData.phone,
            },
            details: {
              ...thisParticipantDetails,
              grauParentesco: grauParent.value,
              dcrGrauParentesco: grauParent.label,
              proporcao: vlrProporcao,
            },
          },
        ])
        if (formData.addNew === 'true') {
          history.push(`${location.pathname}`)
          formRef.current?.reset()
        } else {
          history.push('/participants-list')
        }
      } catch (err) {
        formRef.current?.setErrors(
          getValidationErrors(err as Yup.ValidationError),
        )
      }
    },
    [
      soma,
      thisParticipant,
      thisParticipantData,
      thisParticipantDetails,
      grauParent.value,
      grauParent.label,
      vlrProporcao,
      setParticipants,
      participants,
      userData.cpf,
      history,
      location.pathname,
    ],
  )

  // const handleValidaProporcao = useCallback(
  //   (prop, s) => {
  //     if (prop < 1 || prop > s) {
  //       const msg = `Você já distribuiu ${soma}% da proporção. Restam ${
  //         100 - soma
  //       }% para serem disbribuídos.`
  //       alert(msg)
  //     } else {
  //       setVlrProporcao(prop)
  //     }
  //   },
  //   [soma],
  // )

  const handleOpenModal = useCallback(() => {
    setIsModalOpen(true)
  }, [])

  const handleCloseModal = useCallback(() => {
    setIsModalOpen(false)
  }, [])

  return (
    <>
      <Header />
      <Container>
        <Content>
          <div className="titulo">
            <strong>Beneficiários</strong>
            <AiOutlineQuestionCircle onClick={handleOpenModal} />
          </div>
          <Form
            ref={formRef}
            onSubmit={handleSubmit}
            initialData={{
              name: thisParticipantData.name,
              cpf: thisParticipantData.cpf,
              email: thisParticipantData.email,
              phone: thisParticipantData.phone,
              tipoBen: thisParticipantDetails.tipoBen,
              birthdate: thisParticipantDetails.birthdate,
              proporcao: thisParticipantDetails.proporcao,
            }}
          >
            <InputHidden name="addNew" type="hidden" />
            <InputHidden name="contribution" type="hidden" />
            <InputHidden name="years" type="hidden" />
            <Input placeholder="Nome completo" name="name" icon={FiUser} />
            <InputSelect
              name="grauParentesco"
              value={grauParent}
              options={[
                { label: 'Conjuge', value: '1' },
                { label: 'Companheiro(a)', value: '2' },
                {
                  label: 'Filho(a) não emancipado menor de 21 anos',
                  value: '3',
                },
                { label: 'Filho(a) inválido(a)', value: '4' },
                { label: 'Pai/Mãe', value: '5' },
                {
                  label:
                    'Enteado não emancipado menor de 21 anos com dependência econômica',
                  value: '8',
                },
                {
                  label: 'Enteado inválido com dependência econômica',
                  value: '9',
                },
                {
                  label:
                    'Menor tutelado não emancipado menor de 21 anos com dependência econômica',
                  value: '10',
                },
                {
                  label: 'Menor tutelado inválido com dependência econômica',
                  value: '11',
                },
                { label: 'Neto(a)', value: '13' },
                { label: 'Avô/Avó', value: '17' },
                { label: 'Irmão(ã)', value: '18' },
                { label: 'Sobrinho(a)', value: '19' },
                { label: 'Outros', value: '0' },
              ]}
              placeholder="Grau de parentesco"
              onChange={e => handleChangeGrauParentesco(e)}
            />
            <Input
              placeholder="CPF"
              name="cpf"
              icon={MdSecurity}
              type="tel"
              mask="cpf"
            />
            <Input
              icon={FiMail}
              name="email"
              type="email"
              placeholder="E-mail"
            />
            <Input
              name="phone"
              mask="phone"
              prefix="+55 | "
              placeholder="Celular com DDD"
              icon={FiSmartphone}
            />
            <Input
              placeholder="Proporção"
              icon={FiPercent}
              // eslint-disable-next-line no-restricted-globals
              value={isNaN(vlrProporcao) ? '' : vlrProporcao.toString()}
              name="proporcao"
              mask="percent"
              maxLength={3}
              onChange={e => {
                setVlrProporcao(parseFloat(e.target.value))
                // handleValidaProporcao(
                //   e.target.valueAsNumber,
                //   soma === 0 ? 100 : 100 - soma,
                // )
              }}
            />
          </Form>

          {/* <Button type="button" fontSize="small" onClick={handleAddNovo}>
            Adicionar mais um beneficiário
          </Button> */}
        </Content>

        <Button
          type="button"
          fontSize="normal"
          color="greenBlue"
          onClick={handleJustSave}
        >
          <FiCheck size={45} />
          <span>Pronto! Concluir</span>
        </Button>

        {participants.length > 0 ? (
          <Button
            type="button"
            fontSize="normal"
            color="white"
            onClick={() => history.push('/participants-list')}
          >
            Ver participantes
          </Button>
        ) : (
          <Button
            type="button"
            fontSize="normal"
            color="pink"
            onClick={() => history.push('/conclusion')}
          >
            <span>Não tenho beneficiários</span>
          </Button>
        )}

        <BtnVoltar type="button" onClick={() => history.push('/ppe-fatca')}>
          &lt; Anterior
        </BtnVoltar>
        <Modal
          isOpen={isModalOpen}
          onRequestClose={handleCloseModal}
          overlayClassName="react-modal-overlay"
          className="react-modal-content"
        >
          <FiX onClick={handleCloseModal} />
          <div>
            <strong>
              <u>Beneficiário</u> significará a(s) pessoa(s) física(s)
              expressamente indicada(s) pelo Participante para recebimento do
              benefício de Pecúlio por Morte, dentre os seguintes familiares:
            </strong>
            <br />
            <ul>
              <li>o cônjuge ou Companheiro;</li>
              <li>os filhos, incluindo o enteado e o adotado legalmente;</li>
              <li>os netos;</li>
              <li>os pais;</li>
              <li>os avós;</li>
              <li>os irmãos; e/ou</li>
              <li>os sobrinhos.</li>
            </ul>
          </div>
        </Modal>
      </Container>
    </>
  )
}

export default NovoParticipante
