/* eslint-disable no-alert */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { Base64 } from 'js-base64'

import { FiCheck, FiClock } from 'react-icons/fi'
import { CircularProgress } from '@material-ui/core'

import usePersistedState from '../../hooks/usePersistedState'
import api from '../../services/api'

import Button from '../../components/Button'
import Header from '../../components/Header'

import {
  UserData,
  UserDetails,
  ConfigData,
  ErroProps,
  Participant,
} from '../../utils/interfaces'
import {
  Container,
  Content,
  BtnVoltar,
  Participants,
  Confirmation,
  Line,
  InfoContent,
} from './styles'
import { formatValue } from '../../utils/formatValues'
import ModalBox from '../../components/Modal'

const Conclusion: React.FC = () => {
  const [userData] = usePersistedState<UserData>('userData', {} as UserData)
  const [configData] = usePersistedState<ConfigData>(
    'configData',
    {} as ConfigData,
  )
  const [userDetails] = usePersistedState<UserDetails>(
    'userDetails',
    {} as UserDetails,
  )
  const [participants] = usePersistedState<Participant[]>(
    'participantsGroup',
    [],
  )
  const [, setErroProps] = usePersistedState<ErroProps>(
    'erroProps',
    {} as ErroProps,
  )

  const [acceptInfo] = usePersistedState('acceptInfo', true)
  const [aguarde, setAguarde] = useState(false)
  const [timer, setTimer] = useState(0)
  const [reSend, setReSend] = useState(true)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [warningText, setWarningText] = useState('')

  function handleCloseModal(): void {
    setIsModalOpen(false)
  }

  const history = useHistory()

  const handleConfirmaAdesao = useCallback(async () => {
    setAguarde(true)

    const parametros = Base64.encode(
      `{
        "token": "${configData.token}",
        "versao": "${configData.tipo}",
        "plano": "${configData.plano}",
        "nome": "${userData.name.toLowerCase()}",
        "cpf": "${userData.cpf.replaceAll('.', '').replace('-', '')}",
        "nascimento": "${userData.birthdate}",
        "email": "${userData.email}",
        "telefone": "55${userData.phone
          .replace('(', '')
          .replace(') ', '')
          .replace('-', '')}",
        "patrocinadora":"${userData.patrocinadora}",
        "matricula":"${userData.matricula}",
        "salario": "${userDetails.salario
          .toFixed(2)
          .toString()
          .replace('.', ',')}",
        "valor": "${userDetails.contribuicaoBasica
          .toFixed(2)
          .toString()
          .replace('.', ',')}",
        "valorPatroc": "${userDetails.contribuicaoBasica
          .toFixed(2)
          .toString()
          .replace('.', ',')}",
        "valorsuplementar": "${userDetails.contribuicaoSuplementar
          .toFixed(2)
          .toString()
          .replace('.', ',')}",
        "pctCtbSuplementar": "${userDetails.pctContribuicaoSuplementar
          .toString()
          .replace('.', ',')}",
        "politicamenteexposto": "${userDetails.ppe}",
        "usperson": "${userDetails.usperson}",
        "mrcAceitaContato": "${acceptInfo ? 'S' : 'N'}",
        "tabelaGenerica": "5",
        "ctbFaixa": [
        {
          "porcentagem":"${userDetails.pctContribuicaoBasica
            .toString()
            .replace('.', ',')}",
          "faixa": "1"
        },
        {
          "porcentagem":"${userDetails.pctContribuicaoBasicaFaixa2
            .toString()
            .replace('.', ',')}",
          "faixa": "2"
        },
        {
          "porcentagem":"${userDetails.pctContribuicaoBasicaFaixa3
            .toString()
            .replace('.', ',')}",
          "faixa": "3"
        }],
        "beneficiarios": [${participants.map(
          participant => `
          {"nome": "${participant.data.name}",
          "cpf": "${participant.data.cpf.replaceAll('.', '').replace('-', '')}",
          "email": "${participant.data.email}",
          "telefone": "${participant.data.phone
            .replace('(', '')
            .replace(') ', '')
            .replace('-', '')}",
          "fator": "${participant.details.proporcao
            .toFixed(2)
            .toString()
            .replace('.', ',')}",
          "parentesco": "${participant.details.grauParentesco}"}`,
        )}]
        }`,
    )

    const parametrosFinal = Base64.encode(parametros)

    await api
      .post(`wsAdesao.rule?sys=ADZ&Entrada=${parametrosFinal}`)
      .then(() => {
        localStorage.removeItem('@Previericsson:acceptInfo')
        localStorage.removeItem('@Previericsson:acceptTerms')
        localStorage.removeItem('@Previericsson:configData')
        localStorage.removeItem('@Previericsson:erroProps')
        localStorage.removeItem('@Previericsson:participantsGroup')
        localStorage.removeItem('@Previericsson:totalRentability')
        localStorage.removeItem('@Previericsson:userDetails')
        localStorage.removeItem('@Previericsson:userData')

        history.push('/end')
      })
      .catch(res => {
        if (res.message === 'Request failed with status code 401') {
          alert('Tempo de sessão expirado')
          history.push('/')
        } else if (res.message === 'Request failed with status code 406') {
          setWarningText(
            'Adesão não autorizada. Verifique se você já possui cadastro no plano.',
          )
          setIsModalOpen(true)
          setReSend(false)
          setTimer(20)
          setAguarde(false)
        } else if (res.message === 'Network Error') {
          setErroProps({
            title: res.message,
            description: 'Falha na conexão como servidor',
          })
          history.push('/erro')
        } else if (res.message === 'Request failed with status code 500') {
          setErroProps({
            title: 'Erro interno no servidor',
            description: res.message,
          })
          history.push('/erro')
        } else if (res.message === 'Request failed with status code 408') {
          alert('Tempo de sessão expirado')
          history.push('/')
        } else {
          setReSend(false)
          setTimer(20)
          setAguarde(false)
          setWarningText('Ops, algo deu errado. Tente novamente mais tarde.')
          setIsModalOpen(true)
        }
      })
  }, [
    acceptInfo,
    configData,
    history,
    participants,
    setErroProps,
    userData,
    userDetails,
  ])

  // const dependents = participants.filter(
  //   participant => participant.details.grauParentesco !== undefined,
  // )

  useEffect(() => {
    const countTimer = (): void => {
      if (timer > 1) {
        setTimer(timer - 1)
      } else if (timer === 1) {
        setTimer(timer - 1)
        setReSend(true)
      }
    }
    const interval = setInterval(countTimer, 1000)
    return () => clearInterval(interval)
  }, [timer])

  return (
    <>
      <Header />
      <Container>
        <Content>
          <strong>Resumo</strong>

          <Participants>
            {/* <div className="titular">
              <div className="info">
                <p>{userData.name}</p>
                <small>{calculaIdade(userData.birthdate)} anos</small>
              </div>
            </div> */}

            <InfoContent>
              <div>
                <div>
                  <p>Valor total de contribuição mensal básica ao plano: </p>
                  <small>(participante + patrocinadora)</small>
                </div>
                <strong>
                  {formatValue(userDetails.contribuicaoBasica * 2)}
                </strong>
              </div>
              <div>
                <p>Valor da contribuição mensal voluntária: </p>
                <strong>
                  {formatValue(userDetails.contribuicaoSuplementar)}
                </strong>
              </div>
            </InfoContent>

            {participants.length > 0 ? (
              <>
                <Line />
                <h3>Beneficiários:</h3>
                {participants.map(d => (
                  <div key={Math.random()} className="dependents">
                    <div className="info">
                      <p>{d.data.name}</p>
                      <small>{d.details.dcrGrauParentesco}</small>
                    </div>
                  </div>
                ))}
              </>
            ) : (
              <></>
              // <h3>Nenhum bebeficiário informado.</h3>
            )}
          </Participants>

          <Confirmation>
            <strong>Confirmação de Adesão</strong>
            <p>
              Eu {userData.name}, inscrito no CPF <b>{userData.cpf}</b>, declaro
              que ao clicar em <b>Confirmar Adesão</b>, confirmo minha adesão ao{' '}
              <b>Plano de Contribuição Definida PreviEricsson</b> e que as
              informações prestadas são verídicas.
            </p>
          </Confirmation>

          {/* <div>
            <span> Aceito os termos de uso</span>
            <Switch
              onChange={toggleAcceptTerms}
              checked={acceptTerms}
              checkedIcon={false}
              uncheckedIcon={false}
              height={22}
              width={44}
              handleDiameter={18}
              offColor="#EB6A9F"
              offHandleColor="#fff"
              onHandleColor="#fff"
              onColor="#31D19E"
              disabled={aguarde}
            />
          </div> */}
        </Content>

        <Button
          type="button"
          color="greenBlue"
          width="large"
          disabled={!reSend || aguarde}
          onClick={handleConfirmaAdesao}
        >
          {!aguarde ? (
            !reSend ? (
              <>
                <FiClock size={45} />
                <span>
                  Tente novamente em {timer < 10 ? `0${timer}` : timer} segundos
                </span>
              </>
            ) : (
              <>
                <FiCheck size={45} />
                <span>Confirmar adesão</span>
              </>
            )
          ) : (
            <>
              <CircularProgress color="inherit" />
              <span>Aguarde</span>
            </>
          )}
        </Button>
        <BtnVoltar type="button" onClick={() => history.goBack()}>
          &lt; Anterior
        </BtnVoltar>

        <ModalBox isOpen={isModalOpen} onRequestClose={handleCloseModal}>
          <p>{warningText}</p>
        </ModalBox>
      </Container>
    </>
  )
}

export default Conclusion
