/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-nested-ternary */
import React, { useRef, useCallback, useState, useEffect } from 'react'
import { FormHandles, Scope } from '@unform/core'
import { useHistory } from 'react-router-dom'
import { Form } from '@unform/web'

import { FiCheck, FiX } from 'react-icons/fi'
import _cloneDeep from 'lodash/cloneDeep'

import Header from '../../components/Header'
import Button from '../../components/Button'

import usePersistedState from '../../hooks/usePersistedState'
import calculaIdade from '../../utils/calculaIdade'

import {
  BenefBox,
  Container,
  Content,
  ContentBenef,
  InfoBox,
  Line,
  BtnVoltar,
} from './styles'
import { UserData, Participant } from '../../utils/interfaces'

interface CopiaDependent {
  data: {
    name: string
    cpf: string
    birthdate: string
  }
  details: {
    tipVinculo: string
    tipoBen: string
    grauParentesco: string
    dcrGrauParentesco: string
    proporcao: number
  }
}

const ParticipantsList: React.FC = () => {
  const [userData] = usePersistedState<UserData>('userData', {} as UserData)

  const [participants, setParticipants] = usePersistedState<Participant[]>(
    'participantsGroup',
    [],
  )
  const dependentsListCopy: CopiaDependent[] = _cloneDeep(participants)
  const [partLength, setPartLength] = useState(participants.length)
  const arr = participants.map(participant => participant.details.proporcao)

  const formRef = useRef<FormHandles>(null)
  const history = useHistory()

  let soma = 0
  for (let i = 0; i < arr.length; i += 1) {
    soma += arr[i]
  }
  const [totalProporcao, setTotalProporcao] = useState(soma)

  useEffect(() => {
    setTotalProporcao(soma)
  }, [soma])

  const teste = useCallback(
    async arrAux => {
      await setParticipants([...arrAux])
      setTotalProporcao(100)
    },
    [setParticipants],
  )

  const handleProporcionalizar = useCallback(async () => {
    const value = (100 / partLength).toFixed(2)

    const contador = partLength

    for (let i = 0; i < contador; i += 1) {
      dependentsListCopy[i].details.proporcao = parseFloat(value)
    }
    teste(dependentsListCopy)
  }, [dependentsListCopy, partLength, teste])

  const handleRemove = useCallback(
    id => {
      setParticipants(() =>
        participants.filter((participant, idx) => idx !== id),
      )
    },
    [participants, setParticipants],
  )

  const handleSubmit = useCallback(() => {
    history.push('/conclusion')
  }, [history])

  const handleClickSubmit = useCallback(() => formRef.current?.submitForm(), [])

  useEffect(() => {
    setPartLength(participants.length)
  }, [setParticipants, participants, handleRemove])

  return (
    <>
      <Header />
      <Container>
        <Content>
          <InfoBox>
            <h3>Titular</h3>
            <div>
              <strong>Nome: </strong>
              <label>{userData.name}</label>
            </div>
            <div>
              <strong>Telefone: </strong>
              <label>{userData.phone}</label>
            </div>
            <div>
              <strong>Idade: </strong>
              <label>{calculaIdade(userData.birthdate)} anos</label>
            </div>
          </InfoBox>

          <Form ref={formRef} onSubmit={handleSubmit}>
            <BenefBox>
              {participants.length > 0 ? (
                <h3>Beneficiários</h3>
              ) : (
                <h3>Nenhum beneficiário informado.</h3>
              )}

              {participants.map((participant, idx) => (
                <ContentBenef key={Math.random()}>
                  <Scope path={`parts[${idx}]`}>
                    <FiX onClick={() => handleRemove(idx)} />
                    <div>
                      <div>
                        <strong>Nome: </strong>
                        <label>{participant.data.name}</label>
                      </div>
                      <div>
                        <strong>CPF: </strong>
                        <label>{participant.data.cpf}</label>
                      </div>
                      <div>
                        <strong>E-mail: </strong>
                        <label>{participant.data.email}</label>
                      </div>
                      <div>
                        <strong>Telefone: </strong>
                        <label>{participant.data.phone}</label>
                      </div>
                      <div>
                        <strong>Proporção: </strong>
                        <label>{participant.details.proporcao}%</label>
                      </div>
                      <div>
                        <strong>Parentesco: </strong>
                        <label>{participant.details.dcrGrauParentesco}</label>
                      </div>
                    </div>
                  </Scope>
                  <Line />
                </ContentBenef>
              ))}
            </BenefBox>
          </Form>
        </Content>

        {partLength === 0 ? (
          <Button
            type="button"
            fontSize="normal"
            color="white"
            width="large"
            onClick={() => history.push('/new-participant')}
          >
            Adicionar beneficiário
          </Button>
        ) : (
          <></>
        )}
        {totalProporcao === 100 ? (
          <BenefBox>
            <p>
              Você distribuiu 100% da proporção entre seus beneficiários. Para
              adicionar mais beneficiários será necessário excluir um ou mais
              dentre os informados e refazer o cadastro.
            </p>
          </BenefBox>
        ) : (
          <></>
        )}
        {totalProporcao !== 100 && partLength > 0 ? (
          <>
            <BenefBox>
              <p>
                Você ainda não distribuiu 100% da proporção entre seus
                beneficiários. Adicione mais beneficiários ou{' '}
                <span onClick={() => handleProporcionalizar()}>
                  clique aqui
                </span>{' '}
                para distribuir igualmente entre todos os informados.
              </p>
            </BenefBox>
            <Button
              type="button"
              fontSize="normal"
              color="white"
              width="large"
              onClick={() => history.push('/new-participant')}
            >
              Adicionar mais um beneficiário
            </Button>
          </>
        ) : (
          <>
            <Button
              type="button"
              fontSize="normal"
              color="greenBlue"
              width="large"
              onClick={handleClickSubmit}
            >
              {partLength === 0 ? (
                <span>Não possuo beneficiários</span>
              ) : (
                <>
                  <FiCheck size={45} />
                  <span>Pronto! Avançar</span>
                </>
              )}
            </Button>
          </>
        )}

        {/* {totalProporcao === 100 ? (
          <Button
            type="button"
            fontSize="small"
            color="white"
            width="small"
            onClick={() => history.push('/new-participant')}
          >
            Adicionar mais um beneficiário
          </Button>
        ) : (
          <></>
        )} */}

        <BtnVoltar
          type="button"
          onClick={() => {
            soma === 100
              ? history.push('/ppe-fatca')
              : history.push('/new-participant')
          }}
        >
          &lt; Anterior
        </BtnVoltar>
      </Container>
    </>
  )
}

export default ParticipantsList
